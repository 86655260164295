.react-dropdown-select-dropdown {
  border: none !important;
  padding: 1rem 2rem !important;
  background-color: var(--color-white-1) !important;
}

.react-dropdown-select-dropdown::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

.react-dropdown-select-dropdown::-webkit-scrollbar {
  width: 12px;
  background-color: #f5f5f5;
}

.react-dropdown-select-dropdown::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: var(--color-black-2);
}

.react-dropdown-select-dropdown .react-dropdown-select-item:hover {
  background-color: var(--color-blue);
  border-radius: 5rem !important;
  color: var(--color-white-1);
  transition: background-color 0.5s ease;
}

.form-select {
  font-size: 1.6rem;
}

.mb-50 {
  margin-bottom: 5rem !important;
}
.mb-100 {
  margin-bottom: 10rem !important;
}

.color-red {
  color: var(--color-red) !important;
}
.color-green {
  color: var(--color-green) !important;
}
.color-blue {
  color: var(--color-blue) !important;
}
.color-yellow {
  color: var(--color-yellow) !important;
}

.color-grey-1 {
  color: #676667 !important;
}
.bg-red {
  background-color: var(--color-red) !important;
}
.bg-green {
  background-color: var(--color-green) !important;
}
.bg-blue {
  background-color: var(--color-blue) !important;
}
.bg-yellow {
  background-color: var(--color-yellow) !important;
}

.text-bold {
  font-weight: 700 !important;
}

.mb-24 {
  margin-bottom: 2.4rem !important;
}
