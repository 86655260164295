// Root variable elements
/* 
 example to use this varibles into diff scss files
 background-color: var(--color-primary);
*/

:root {
  --color-yellow: #ffcb34;
  --color-green: #0da650;
  --color-blue: #067de1;
  --color-red: #db4a3b;

  --color-yellow-light-1: #ffdf81;

  --color-grey-light-1: #676667;
  --color-grey-light-2: #5a6368;
  --color-grey-light-3: #969696;
  --color-grey-light-4: #eef0f4;
  --color-grey-light-5: #424f56;

  --color-white-1: #ffffff;
  --color-white-2: #fcfcfc;
  --color-white-3: #f4f7fb;
  --color-white-4: #e5e5e5;
  --color-white-5: #f5f5f5;
  --color-white-6: #e1e4ec;

  --color-black-1: #000000;
  --color-black-2: #231f20;
  --color-black-3: #1c1c1c;
}

$bp-extralarge: 78.15em; // 1920px

$bp-large: 78.15em; // 1250px
$bp-medium: 61.25em; // 980px
$bp-small: 37.5em; // 600px
$bp-smallest: 31.25em; // 500px
